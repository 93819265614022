
import { Options, Vue } from "vue-class-component";

type Feature = {
  Type: string;
  Year: string;
  Title: string;
};

@Options({
  props: {
    feature: Object
  }
})
export default class FeatureCard extends Vue {
  feature!: Feature;
}
