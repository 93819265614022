
import { ref } from "vue";

export default {
  name: "Search",
  props: ["search", "placeholder"],
  setup(props: Readonly<{ search?: string }>, { emit }: Record<string, any>) {
    const searchTerm = ref(props.search);

    const handleSearch = (): void => {
      emit("search", searchTerm.value);
    };

    const handleChange = (event: any): void => {
      searchTerm.value = event.target.value;
    };

    return {
      searchTerm,
      handleSearch,
      handleChange
    };
  }
};
