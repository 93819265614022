
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    title: String,
    subtitle: String
  }
})
export default class Header extends Vue {
  title!: string;
  subtitle!: string;
}
