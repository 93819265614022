
import Loader from "@/components/Loading.vue";
import FeatureCard from "@/components/FeatureCard.vue";
import Search from "@/components/Search.vue";

import useMovieApi from "@/hooks/movieApi";
import { onBeforeMount } from "vue";

export default {
  name: "Home",
  components: {
    Loader,
    FeatureCard,
    Search
  },
  setup() {
    const state = useMovieApi();

    const handleSearch = (searchTerm: string) => {
      state.search = searchTerm;
    };

    onBeforeMount(() => {
      state.search = "Batman";
    });

    return {
      state,
      handleSearch
    };
  }
};
