
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    loading: Boolean,
    msg: String
  }
})
export default class Loading extends Vue {
  loading!: boolean;
  subtitle!: string;
}
